import { template as template_da47b0091c754e2393bb55d2ff0b4bdf } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_da47b0091c754e2393bb55d2ff0b4bdf(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
