import { template as template_f8f16e72a04846da84ba7eeb5737e3d9 } from "@ember/template-compiler";
const SidebarSectionMessage = template_f8f16e72a04846da84ba7eeb5737e3d9(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
